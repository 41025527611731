<template>
    <label
        :class="['kk-toggle', { loading: internalLoading, disabled }]"
        :style="`--width: ${width}; --height: ${height};`"
        tabindex="0"
        @keydown.space="onClick"
        @click.prevent="onClick"
    >
        <slot />
        <div
            class="toggle-switch"
            :class="{ disabled }"
        >
            <input
                :checked="checked"
                :disabled="disabled || loading"
                type="checkbox"
            >
            <div :class="['slider', 'round', { disabled }]" />
        </div>
        <slot name="right" />
    </label>
</template>

<script>
export default {
    name: 'kk-toggle',

    props: {
        value: {
            default: false,
            type: Boolean,
        },
        width: {
            default: '37px',
            type: String,
        },
        height: {
            default: '16px',
            type: String,
        },
        disabled: {
            default: false,
            type: Boolean,
        },
        loading: {
            default: false,
            type: Boolean,
        },
    },
    data() {
        return {
            checked: false,
            internalLoading: false,
        };
    },
    watch: {
        value: {
            immediate: true,
            handler(value) {
                this.checked = value;
            },
        },
        /**
         * Add delay for loading so the animation does not break if connection is fast.
         */
        loading: {
            immediate: true,
            handler(loading) {
                if (loading === true) {
                    this.internalLoading = loading;
                } else {
                    setTimeout(() => {
                        this.internalLoading = loading;
                    }, 600);
                }
            },
        },
    },
    methods: {
        onClick() {
            if (this.disabled) {
                return;
            }

            this.$emit('input', !this.checked);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~sass/_variables.scss';

.kk-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @keyframes rotate {
        0%,
        15% {
            opacity: 1;
            transform: rotate(0deg);
        }
        50% {
            transform: rotate(290deg);
        }
        100% {
            opacity: 1;
            transform: rotate(360deg);
        }
    }

    &.loading {
        .toggle-switch {
            .slider {
                width: var(--height);
                transform: translateX(calc(var(--width) / 4));
                background-color: #ccc !important;

                &::before {
                    animation: rotate 900ms infinite linear;
                    animation-delay: 300ms;
                    transform: translateX(0);
                }
                &::after {
                    transform: translateX(0);
                    opacity: 0;
                }
            }
            input {
                &:checked + .slider::after {
                    transform: translateX(0);
                }
            }
        }
    }

    .toggle-switch {
        position: relative;
        display: inline-block;
        /* IE fallback */
        width: 37px;
        height: 16px;
        /* END IE fallback */
        width: var(--width);
        height: var(--height);
        margin: 0 5px;
        outline: none;

        input {
            display: none;

            &:checked + .slider {
                background-color: #2196F3;
            }
            &:focus + .slider {
                box-shadow: 0 0 1px #2196F3;
            }
            &:checked + .slider::after {
                /* IE fallback */
                transform: translateX(19px);
                /* END IE fallback */
                transform: translateX( calc(var(--width) - calc(var(--height) - -1px) ) );
            }
        }

        .slider {
            cursor: pointer;
            background-color: #ccc;
            transition: all 400ms ease-in-out;
            border-radius: 34px;
            width: 100%;
            height: 100%;

            @mixin slider-button {
                content: "";
                opacity: 1;
                position: absolute;
                /* IE fallback */
                height: calc(16px - 4px);
                width: calc(16px - 4px);
                /* END IE fallback */
                height: calc(var(--height) - 4px);
                width: calc(var(--height) - 4px);
                left: 1.5px;
                bottom: 2px;
                background-color: #fff;
                transition: all 400ms ease-in-out;
                border-radius: 50%;
                box-sizing: border-box;
                border: 3px solid transparent;
            }

            &.disabled {
                background-color: var(--input-background-disabled) !important;
            }

            &::before {
                @include slider-button;
                border-top-color: #fff;
                background: transparent;
                opacity: 0;
            }
            &::after {
                @include slider-button;
            }
        }
    }
}

.disabled {
    cursor: not-allowed !important;
    pointer-events: all !important;
}
</style>
