export const Features = Object.freeze({
    MAIN_NAV_FEATURES_TOGGLE: 'MAIN_NAV_FEATURES_TOGGLE',
    MAIN_HEADER_MAILBOOK: 'MAIN_HEADER_MAILBOOK',
    MAIN_HEADER_LARAVEL_PULSE: 'MAIN_HEADER_LARAVEL_PULSE',
    MOBILE_DASHBOARD: 'MOBILE_DASHBOARD',
    MOBILE_DASHBOARD_STATISTIC_TIME_TRACKING_WIDGET: 'MOBILE_DASHBOARD_STATISTIC_TIME_TRACKING_WIDGET',
    MOBILE_DASHBOARD_STATISTIC_CHECKLIST_WIDGET: 'MOBILE_DASHBOARD_STATISTIC_CHECKLIST_WIDGET',
    MOBILE_DASHBOARD_STATISTIC_DEVIATIONS_WIDGET: 'MOBILE_DASHBOARD_STATISTIC_DEVIATIONS_WIDGET',
    MOBILE_DASHBOARD_STATISTIC_PROCEDURES_WIDGET: 'MOBILE_DASHBOARD_STATISTIC_PROCEDURES_WIDGET',
    DASHBOARD_STATISTIC_TIME_TRACKING_WIDGET: 'DASHBOARD_STATISTIC_TIME_TRACKING_WIDGET',
    DASHBOARD_STATISTIC_CHECKLIST_WIDGET: 'DASHBOARD_STATISTIC_CHECKLIST_WIDGET',
    DASHBOARD_STATISTIC_DEVIATIONS_WIDGET: 'DASHBOARD_STATISTIC_DEVIATIONS_WIDGET',
    DASHBOARD_STATISTIC_PROCEDURES_WIDGET: 'DASHBOARD_STATISTIC_PROCEDURES_WIDGET',
});

export const FeaturesToggleLocalStorageKey = 'featuresToggle';
