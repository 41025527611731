<template>
    <div class="kk-textarea">
        <kk-form-title
            :title="label"
            :sub-title="subLabel"
        />
        <div class="textarea-wrapper">
            <textarea
                ref="textarea"
                v-observe-visibility="onChangeVisibility"
                :value="value"
                :maxlength="maxLength"
                v-bind="$attrs"
                v-on="listeners"
            />
            <span v-if="$slots['bottom-left']" class="bottom-left">
                <slot name="bottom-left" />
            </span>
            <span class="bottom-right">
                <slot name="bottom-right">
                    <span v-if="count" class="limit" v-text="count" />
                </slot>
            </span>
        </div>
    </div>
</template>

<script>
// Polyfill for the intersection observer API that is used by vue-observe-visability
// It is not natively supported by iOS.
import 'intersection-observer';

import kkFormTitle from '../kk-form-title/kk-form-title.vue';
import { ObserveVisibility } from 'vue-observe-visibility';

export default {
    name: 'kk-textarea',
    components: { kkFormTitle },

    directives: {
        ObserveVisibility,
    },
    props: {
        value: {
            required: true,
            validator: val => typeof val === 'string' || val === null,
        },

        /**
        * Label/title text for input.
        */
        label: {
            type: String,
            default: '',
        },

        /**
         * Max amount of characters allowed.
         */
        maxLength: {
            default: null,
            type: Number,
        },

        /**
        * sub-Label/sub-title text.
        * Used to describe title
        */
        subLabel: {
            type: String,
            default: '',
        },
    },

    computed: {
        count() {
            if (this.maxLength === null) {
                return null;
            }

            if (this.value === null) {
                return `0/${this.maxLength}`;
            }

            return `${this.value.length}/${this.maxLength}`;
        },
        listeners() {
            const vm = this;

            return Object.assign({}, this.$listeners, {
                input(event) {
                    vm.$emit('input', event.target.value);
                    vm.reComputeTextareaHeight(event.target);
                },
            });
        },
    },

    watch: {
        value() {
            // Recompute the height when the value changes
            this.$nextTick(() => {
                this.reComputeTextareaHeight(this.$refs.textarea);
            });
        },
    },

    methods: {
        onChangeVisibility(isVisible, entry) {
            this.reComputeTextareaHeight(entry.target);
        },

        /**
         * Method used to re-compute the textarea's height.
         * Don't need this to be able to acces the internal scope, thus the «name: (parms) => {}» declaration.
         */
        reComputeTextareaHeight: (el) => {
            setTimeout(() => {
                const currentHeight = el.clientHeight; // Get the current height
                const newHeight = el.scrollHeight; // Get the new height based on content

                // Resize the textarea only if the new height is greater than the current height
                if (newHeight > currentHeight) {
                    el.style.height = (newHeight + 2) + 'px'; // Ads 2px to avoid a tiny scroll bar inside textarea
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.kk-textarea {
    .textarea-wrapper {
        textarea {
            background-color: white;
            border: 1px solid var(--border-input);
            border-radius: var(--radius-primary);
            box-sizing: border-box;
            color: var(--text-primary);
            display: block;
            font: var(--font-body-sm-regular);
            letter-spacing: 0;
            margin: 0;
            min-height: 64px; // Two lines of text.
            padding: var(--spacing-1) var(--spacing-1_5);
            resize: vertical;
            width: 100%;

            &::placeholder {
                color: var(--text-secondary);
            }

            &:disabled {
                background-color: var(--input-background-disabled);
                color: var(--text-disabled);
            }
        }

        .bottom-left,
        .bottom-right {
            color: var(--text-secondary);
            display: block;
            font: var(--font-body-xs-regular);
            margin-top: var(--spacing-0_25);
        }
        .bottom-right {
            text-align: right;
        }
    }
}
</style>
